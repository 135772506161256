import React from 'react';
import Helmet from 'react-helmet';
import { Header, Dropdown, Button } from 'semantic-ui-react';
import Masonry from 'react-masonry-component';

import Layout from '../components/Layout';
import InTheWildImage from '../components/InTheWildImage';
import '../style/gallery.css';

const artTypes = [
  "all",
  "Original",
  "Giclee Print",
  "Canvass Wrap",
  " Dye Sublimation onto Metal ",
];

const typeFilterOptions = artTypes.map(filterType => ({
  key: filterType,
  value: filterType,
  text: filterType.toUpperCase()
}));

const locationTypes = [
  "all",
  "Home",
  "Public",
  "Gallery",
  "Commercial Space",
  "Press",
  "Museum"
];

const locationFilterOptions = locationTypes.map(filterType => ({
  key: filterType,
  value: filterType,
  text: filterType.toUpperCase()
}));

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterChoice1: 'all',
      filterChoice2: 'all',
      initialGalleryImages: [],
      currentGalleryImages: [],
      toShowIndex: 20,
      carouselIndex: 0,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onSeeMore = this.onSeeMore.bind(this);
    this.toggleCarousel = this.toggleCarousel.bind(this);
    this.setCarouselIndex = this.setCarouselIndex.bind(this);
  }

  componentDidMount() {
    const { edges } = this.props.data.allAirtable;
    const galleryImages = edges.map((edge) => edge.node.data);

    this.setState({ currentGalleryImages: galleryImages, initialGalleryImages: galleryImages })
  }

  toggleCarousel(direction) {
    const { carouselIndex } = this.state;

    if (direction === 'left') {
      this.setState({ carouselIndex: carouselIndex - 1 })
    } else if (direction === 'right') {
      this.setState({ carouselIndex: carouselIndex + 1 })
    }
  }

  setCarouselIndex(i) {
    this.setState({ carouselIndex: i })
  }

  onSeeMore() {
    const { initialGalleryImages, toShowIndex } = this.state;


    if (initialGalleryImages.length - toShowIndex < 20) {
      this.setState({ toShowIndex: initialGalleryImages.length, carouselIndex: 0 })
    } else {
      const newIndex = toShowIndex + 20 > initialGalleryImages.length ? initialGalleryImages.length : toShowIndex + 20;
      this.setState({ toShowIndex: newIndex, carouselIndex: 0 })
    }
  }

  onFilterChange(e, { value, name }) {
    const { initialGalleryImages, filterChoice1, filterChoice2 } = this.state;

    const filterChoice = name === "typeFilter"
      ? { filterChoice1: value }
      : { filterChoice2: value };

    const filter1 = name === "typeFilter" ? value : filterChoice1;
    const filter2 = name === "locationFilter" ? value : filterChoice2;

    let filteredGalleryImages = initialGalleryImages
      .filter(({ Type }) => filter1 === 'all' || (Type && Type.includes(filter1)))
      .filter(({ Location }) => filter2 === 'all' || (Location && Location === filter2));

    this.setState({
      ...filterChoice,
      currentGalleryImages: filteredGalleryImages,
      currentPage: 1,
      rangeLow: 0,
      rangeHigh: 8 > filteredGalleryImages.length ? filteredGalleryImages.length : 8
    })
  }

  render() {
    const {
      filterChoice1,
      filterChoice2,
      currentGalleryImages,
      initialGalleryImages,
      toShowIndex,
      carouselIndex
    } = this.state;
    return (
      <Layout>
        <Helmet
          title={`See Art by Boston Artist Ari Hauben in the Wild`}
          meta={[
            { name: 'description', content: 'Ari Hauben’s art can be found in homes, commercial and public spaces and museums throughout Boston, NYC and beyond.' },
          ]}
        />
        <div>
          <Header className="gallery-filter-header">TYPE:</Header>
          <Dropdown
            className="gallery-filter-dropdown"
            placeholder="TYPE"
            name="typeFilter"
            onChange={this.onFilterChange}
            value={filterChoice1}
            text={filterChoice1.toUpperCase()}
            selection
            options={typeFilterOptions}
          />
          <Header style={{ marginTop: 0 }} className="gallery-filter-header">LOCATION:</Header>
          <Dropdown
            className="gallery-filter-dropdown"
            placeholder="TYPE"
            name="locationFilter"
            onChange={this.onFilterChange}
            value={filterChoice2}
            text={filterChoice2.toUpperCase()}
            selection
            options={locationFilterOptions}
          />
        </div>
        <Masonry
          className={'my-gallery-class'}
          elementType="div"
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {currentGalleryImages.length > 0
            ? currentGalleryImages.slice(0, toShowIndex).map((image, index) => (
              <InTheWildImage
                key={image.Title}
                carouselIndex={carouselIndex}
                onToggle={this.toggleCarousel}
                image={image}
                initialIndex={index}
                toShow={toShowIndex}
                currentGalleryImages={currentGalleryImages}
                setCarouselIndex={this.setCarouselIndex}
              />
            ))
            : <h4>No Results</h4>
          }
        </Masonry>
        {initialGalleryImages.length !== toShowIndex
          && (
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
              <Button className="see-more-gallery-btn" onClick={this.onSeeMore}>See More</Button>
            </div>
          )
        }
      </Layout>
    );
  }
};

export const query = graphql`
  query {
    allAirtable(filter: {table: {eq: "Art in the Wild"}}, sort: {fields: data___Ordinal}) {
      edges {
        node {
          data {
            Title
            Image {
              url
            }
            ImageURL
            Type
            Location
            Description
            Hyperlink
          }
        }
      }
    }
  }
`;

